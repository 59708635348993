.backgroundImage {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 2000px) {
  .backgroundImage {
    height: 682px;
  }
}
